import React, { useState, useEffect } from 'react';
import './ProductListing.css';
import products from './products';
import GenAiIcon from './GenAiIcon';
import { ReactComponent as CheckIcon } from './checkout_check.svg';


const ProductListing = () => {
  const [currentProduct, setCurrentProduct] = useState(products[0]);
  const [isDimensionVisible, setIsDimensionVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [imageStyle, setImageStyle] = useState({});
  const [isScaled, setIsScaled] = useState(false);
  const [lastProduct, setLastProduct] = useState(null);

  // Function to preload images
  const preloadImages = () => {
    products.forEach(product => {
      const imgDisplay = new Image();
      imgDisplay.src = product.displayImage;
      const imgDimension = new Image();
      imgDimension.src = product.dimensionImage;
    });
  };

  useEffect(() => {
    preloadImages();
  }, []);

  const getNextProductIndex = () => {
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * products.length);
    } while (products[nextIndex].id === currentProduct.id || products[nextIndex].id === lastProduct?.id);
    return nextIndex;
  };

  const handleChangeProduct = () => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 600);

    const nextIndex = getNextProductIndex();
    setImageStyle({ opacity: 0.8 });
    setTimeout(() => setImageStyle({ opacity: 1 }), 400);

    setLastProduct(currentProduct);
    setCurrentProduct(products[nextIndex]);
    setIsDimensionVisible(false);
  };

  const toggleDimension = () => {
    setIsDimensionVisible(!isDimensionVisible);
    setIsScaled(true);
    setTimeout(() => setIsScaled(false), 300);
  };

  return (
    <>
      <style>{`
        @keyframes scaleShapeButton {
          0%, 100% { transform: translate(-50%, 50%) scale(1); }
          50% { transform: translate(-50%, 50%) scale(0.99); }
        }
      `}</style>
      <div className="product-listing">
        <div className="image-container">
          <img 
            src={isDimensionVisible ? currentProduct.dimensionImage : currentProduct.displayImage} 
            alt={currentProduct.name} 
            style={imageStyle}
            loading='lazy'
          />
          <button 
            className={`change-shape-btn ${isAnimating ? 'scale-animation' : ''}`} 
            onClick={handleChangeProduct}
            style={isAnimating ? { animation: 'scaleShapeButton 0.3s ease' } : {}}
          >
            <GenAiIcon isSpinning={isAnimating} />
            שינוי חדר
          </button>
        </div>
        <div className="product-info">
          <div className="product-text">
            <div className="name-and-price">
              <p className="product-name">{currentProduct.name}</p>
              <p className="product-price">{currentProduct.price}₪</p>
            </div>
            <div className="discount-and-tag">
            <p className="discounted-price">{currentProduct.discountedPrice}₪</p>
            <p className="discount-tag">מבצעי פסח 🧇</p>
            </div>
          </div>
          <button 
            className={`dimension-btn ${isScaled ? 'dimension-btn-scale-down' : ''}`}
            onAnimationEnd={() => setIsScaled(false)}
            onClick={toggleDimension}
          >
            {isDimensionVisible ? <img className='dim-x' src="/assets/images/genx.png" alt="Close" /> 
            : <img src="/assets/images/genruler.png" alt="Ruler" />}
          </button>
        </div>
        <div className="upload-link">
          <a href="/upload-your-image">מסגרו את התמונות שלכם</a>
        </div>
      </div>
    </>
  );
};

export default ProductListing;